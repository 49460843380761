import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../../components/menus/bcmenu';
import InlineBodyTransform from '../../../components/inlinebodytransform';
import Layout from '../../../components/layout';
import React from 'react';
import SEO from '../../../components/seo';
import SideNavBar from '../../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const post = data.nodeAboutUsPages
  let backgroundImage = ''
  if (post.relationships.field_image_banner != null) {
    backgroundImage = post.relationships.field_image_banner.localFile.childImageSharp.aboutus.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight editCont">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {post.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              <InlineBodyTransform bodyValue={post.body} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodeAboutUsPages(id: { eq: $id }) {
      id
      title
      body {
        value
      }
      created
      relationships {
        field_image_banner {
          localFile{
            childImageSharp {
              aboutus : original {
                src
              }
            }
          }
        }
      }
    }
    
    # Menu relation
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          title
          link {
            uri
          }
          menu_name
        }
      }
    }
  }
`